import Axios from "axios";
import cookieCutter from "cookie-cutter";

const axiosAuth = Axios.create({
    baseURL: process.env.NEXT_PUBLIC_AUTH_SERVICE_URL,
    headers: {
        "X-Requested-With": "XMLHttpRequest",
    },
});

axiosAuth.interceptors.request.use(
    (config) => {
        const token = cookieCutter.get("accessToken");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosAuth.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response?.status === 401) {
            try {
                const newToken = await handleTokenExpiry();

                if (newToken) {
                    cookieCutter.set("accessToken", newToken, {
                        path: "/",
                        domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                        secure: true,
                    });
                    originalRequest.headers.Authorization = `Bearer ${newToken}`;
                    return axiosAuth(originalRequest);
                } else {
                    window.location.href = "/login";
                }
            } catch (error) {
                throw error;
            }
        }
        return Promise.reject(error);
    }
);

export default axiosAuth;
