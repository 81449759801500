import { createApi } from "@reduxjs/toolkit/query/react";
import { GraphQLClient } from "graphql-request";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import cookieCutter from "cookie-cutter";
import { handleTokenExpiry } from "@/lib/handleTokenExpiry";

const client = new GraphQLClient(
    `${process.env.NEXT_PUBLIC_BACKEND_URL}/graphql`,
    {
        credentials: "include",
        headers: {
            Accept: "application/json",
        },
    }
);

const baseQuery = graphqlRequestBaseQuery({
    client,
    prepareHeaders: (headers) => {
        const token = cookieCutter.get("accessToken");
        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
    }
});

const enhancedBaseQuery = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);
    if (result.error) {
        const parsedError = result.meta?.response?.errors;
        if (parsedError === "Expired token") {
            const newToken = await handleTokenExpiry();
            if (newToken) {
                const newHeaders = new Headers(args.headers || {});
                newHeaders.set("Authorization", `Bearer ${newToken}`);
                return baseQuery({ ...args, headers: newHeaders }, api, extraOptions);
            } else {
                window.location.href = "/login";
                return result;
            }
        } else {
            throw result.error;
        }
    } 

    return result;
};

export const api = createApi({
    baseQuery: enhancedBaseQuery,
    tagTypes: [
        "getCandidates",
        "unlockCandidate",
        "getUserInterviews",
        "getInterviewData",
        "getCandidateInterview",
        "getQuestions",
        "getInterviewTypes",
        "getRequirementsByActiveState",
        "getCurrentUser",
    ],
    endpoints: () => ({}),
});
