import cookieCutter from "cookie-cutter";
import axiosAuth from "@/lib/axiosAuth";

export const handleTokenExpiry = async () => {
    try {
        const response = await axiosAuth.post("/api/auth/refresh-token", {
            refreshToken: cookieCutter.get("refreshToken"),
        });

        if (response.status === 200) {
            const { accessToken, refreshToken } = response.data;
            cookieCutter.set("accessToken", accessToken, {
                path: "/",
                domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                secure: true,
            });
            if (refreshToken) {
                cookieCutter.set("refreshToken", refreshToken, {
                    path: "/",
                    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                    secure: true,
                });
            }
            return accessToken;
        } else {
            throw new Error("Token refresh failed");
        }
    } catch (error) {
        throw new Error("Token refresh failed");
    }
};